// import React, { useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import axios from 'axios';
// import GoogleDocsViewer from 'react-google-docs-viewer';
// import CircularProgress from '@mui/material/CircularProgress';
// import './App.css'; 

// const App = () => {
//   const [filePath, setFilePath] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const onDrop = async (acceptedFiles) => {
//     const formData = new FormData();
//     formData.append('file', acceptedFiles[0]);

//     setLoading(true); 

//     try {
//       const response = await axios.post('https://viewer.systoolsacademy.com/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       setFilePath(response.data.filePath);
//     } catch (error) {
//       console.error('Error uploading file:', error);
//     }

//     setLoading(false);  
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     accept: '.doc,.docx'
//   });

//   return (
//     <div className="App">
//       <div
//         className="container"
//         style={{
//           border: "2px dashed black",
//           cursor: "pointer",
//           position: "relative",
//         }}
//       >
//         <div className="row justify-content-center">
//           <div className="col-lg-8 text-center">
//             <p className="text-center pt-5 pb-3">
//               Preview your data on browser and keep your data safe on device
//             </p>
//             <button className="btn btn-primary" {...getRootProps()}>
//               <input {...getInputProps()} accept=".doc,.docx" />
//               {loading ? (
//                 <>
                
//                 <CircularProgress size={24} style={{ color: 'white' }} />
//                 <p className='mt-2 fw-bold'>please wait file is loading</p>
//                 </>
                
           
//               ) : (
//                 <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//                   Select Files
//                 </p>
//               )}
//             </button>
//             <p className="pt-4 pb-5">
//               <img
//                 title="Lock"
//                 width="20px"
//                 height="20px"
//                 src={''}
//                 alt=""
//               />
//               Your data stays safe on your device, and previews happen in your
//               web browser
//             </p>
//           </div>
//         </div>
//       </div>

//       {filePath && !loading && (
//         <div style={{ marginTop: '20px' }}>
//           <GoogleDocsViewer
//             width="100%"
//             height="780px"
//             fileUrl={`https://viewer.systoolsacademy.com${filePath}`}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default App;



import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import GoogleDocsViewer from 'react-google-docs-viewer';
import CircularProgress from '@mui/material/CircularProgress';
import './App.css'; 

const App = () => {
  const [filePath, setFilePath] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDrop = async (acceptedFiles) => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    setFileName(acceptedFiles[0].name); // Set the filename
    setLoading(true); 

    try {
      const response = await axios.post('https://viewer.systoolsacademy.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setFilePath(response.data.filePath);
    } catch (error) {
      console.error('Error uploading file:', error);
      setFileName(null); // Reset filename on error
    }

    setLoading(false);  
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.doc,.docx'
  });

  return (
    <div className="App">
      <div
        className="container"
        style={{
          border: "2px dashed black",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <p className="text-center pt-5 pb-3">
              Preview your data on browser and keep your data safe on device
            </p>
            <button className="btn btn-primary" {...getRootProps()}>
              <input {...getInputProps()} accept=".doc,.docx" />
              {loading ? (
                <>
                  <CircularProgress size={24} style={{ color: 'white' }} />
                  <p className="mt-2 fw-bold">Please wait, file is loading...</p>
                </>
              ) : filePath ? (
                <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
                  {fileName}
                </p>
              ) : (
                <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
                  Select Files
                </p>
              )}
            </button>
            <p className="pt-4 pb-5">
              <img
                title="Lock"
                width="20px"
                height="20px"
                src={''}
                alt=""
              />
              Your data stays safe on your device, and previews happen in your
              web browser
            </p>
          </div>
        </div>
      </div>

    

      {filePath && !loading && (
        <div style={{ marginTop: '20px' }}>
          <GoogleDocsViewer
            width="100%"
            height="780px"
            fileUrl={`https://viewer.systoolsacademy.com${filePath}`}
          />
        </div>
      )}
    </div>
  );
};

export default App;

